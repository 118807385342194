import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from 'environments/environment';
import { Observable, of, timer } from 'rxjs';
import { take, map, catchError, switchMap } from 'rxjs/operators';

export class BankValidationModel {
    valid: boolean;
    bankName: string;
}

@Injectable({
    providedIn: 'root'
})
export class BsbValidator {
    constructor(private httpClient: HttpClient) {
        this.validateBsb = this.validateBsb.bind(this);
    }

    validateBsb(control: AbstractControl): Observable<ValidationErrors | null> {
        if (!control.value) {
            return of(null);
        }

        return timer(500).pipe(
            switchMap(() =>
                this.httpClient
                    .post<BankValidationModel>(
                        `${environment.api.baseUrl}/bankDetails/validateBsb/${control.value}`,
                        {}
                    )
                    .pipe(
                        take(1),
                        map(({ valid }) => {
                            if (valid === false) {
                                return { isBSBValid: false };
                            }
                            return null;
                        }),
                        catchError(err => {
                            return of({ isBSBValid: false });
                        })
                    )
            )
        );
    }
}
